<template>
  <div class="baoming">
    <!-- 报名页面 -->
    <el-table :data="bm" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column label="姓名">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.s_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="讲师">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.jiangshi }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="联系电话">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.s_tel }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="时间">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.s_date }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <div v-if="scope.row.state == '已报名'" slot="reference" class="ybm">
            {{ scope.row.state }}
          </div>
          <div
            v-else-if="scope.row.state == '已进班'"
            slot="reference"
            class="yjb"
          >
            {{ scope.row.state }}
          </div>
          <div
            v-else-if="scope.row.state == '未报名'"
            slot="reference"
            class="wbm"
          >
            {{ scope.row.state }}
          </div>
          <div v-else slot="reference" class="ybm">
            {{ scope.row.state }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="编辑">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            @click="deleteAction(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "BaoMing",
  data: function () {
    return {
      bm: [],
    };
  },
  async created() {
    this.bm = this.$route.query.data.sx;
    let phones = this.bm.map((item) => item.s_tel);
    phones = phones.join(",");
    //查询学生状态
    this.$datas.meta = { phones };
    let res = await this.$datas.bmStateCheck;
    let datas = res.data.msg.data;
    for (let i in datas) {
      let item = datas[i];
      for(let j in this.bm){
        if(item.customer_Mdn == this.bm[j].s_tel){
            this.bm[j].state = item.customer_Status_Name;
            this.bm[j].s_date = item.customer_Status_Name == "已结清" ? item.jieQingTime:(item.customer_Status_Name == '退款' ? item.tuiFeiTime:item.customer_JoinTime);
            console.log(item);
            this.$datas.meta = { id: this.bm[j].id, ...this.bm[j]};
            await this.$datas.updateBM;
            break;
        }
      }  
    }
  },
  methods: {
    async deleteAction(id) {
      this.$datas.meta = { id };
      let res = await this.$datas.bmDelete;
      // 删除页面数据
      if (res.status == 204) {
        for (let i in this.bm) {
          if (this.bm[i].id == id) {
            this.bm.splice(i, 1);
            break;
          }
        }
      }
    },
  },
};
</script>
<style lang="less">
.baoming {
  padding: 20px 30px;

  .ybm {
    color: #409eff;
  }
  .yjb {
    color: #67c23a;
  }
  .ytx {
    color: #f56c6c;
  }
  .wbm {
    color: orangered;
  }
}
</style>
